import React, { useState, createContext } from "react";
import PropTypes from "prop-types";

const ReplyMessageContext = createContext();

const ReplyMessageProvider = ({ children }) => {
  const [replyingMessage, setReplyingMessage] = useState(null);

  return (
    <ReplyMessageContext.Provider
      value={{ replyingMessage, setReplyingMessage }}
    >
      {children}
    </ReplyMessageContext.Provider>
  );
};
ReplyMessageProvider.propTypes = {
  children: PropTypes.node,
};

export { ReplyMessageContext, ReplyMessageProvider };

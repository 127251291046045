import React from "react";
import { Button, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
// errors
import toastError from "../errors/toastError";

const LocationPreview = ({ link, description }) => {
  // @
  const handleLocation = async() => {
    try {
      window.open(link);
    } catch (err) {
      toastError(err);
    }
  };

  const coords = (description || "").split(",");
  const api = process.env.REACT_APP_MAPS_API;
  let lat = null;
  let lng = null;
  if (coords.length === 2) {
    lat = coords[0].trim();
    lng = coords[1].trim();
  }

  return (
    <>
      <div style={{
        minWidth: "250px",
      }}>
        <div>
          {lat && lng && (
            <iframe
              width="345"
              height="200"
              frameBorder="0"
              src={`https://www.google.com/maps/embed/v1/view?key=${api}&center=${lat},${lng}&zoom=15`}
              allowFullScreen
            />
          )}
          <div style={{ display: "block", content: "", clear: "both" }}></div>
          <div>
            <Divider />
            <Button
              fullWidth
              color="primary"
              onClick={handleLocation}
              disabled={!link}
            >Visualizar</Button>
          </div>
        </div>
      </div>
    </>
  );

};
LocationPreview.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
};

export default LocationPreview;
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { IconButton, makeStyles } from "@material-ui/core";

import { GetApp, PictureAsPdf, InsertDriveFile, Image as ImageIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  messageMediaFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f6f6",
    borderRadius: "7.5px",
    padding: 10,
    margin: 3,
  },
  messageMediaFileInput: {
    backgroundColor: "#f5f6f6",
  },
  messageMediaFileOutput: {
    backgroundColor: "#d1f4cc",
  },

  fileIcon: {
    "& svg": {
      fontSize: "2.5rem",
    },
  },
  filenameContentItem: {
    marginLeft: theme.spacing(1),
  },
  fileDetails: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    fontSize: ".6875rem",
    color: "#8696a0",
    textTransform: "uppercase",
  },
}));
const MessageMediaDocument = ({ message }) => {
  // !
  // Styles
  const classes = useStyles();

  const messageMediaFileFrom = message.fromMe ? classes.messageMediaFileOutput : classes.messageMediaFileInput;

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
  };

  const getIcon = (mediaExtension) => {
    switch(mediaExtension) {
      case "pdf":
        return <PictureAsPdf style={{ color: "red" }} />;

      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <ImageIcon />;

      default:
        return <InsertDriveFile />;
    }
  };

  return (
    <div className={clsx(classes.messageMediaFile, messageMediaFileFrom)}>
      <div className={classes.fileIcon}>{getIcon(message.mediaExtension)}</div>
      <div className={classes.filenameContentItem}>
        {message.body}
        <div className={classes.fileDetails}>
          <span>{message.mediaExtension}</span>
          <span>•</span>
          <span>{formatFileSize(message.mediaSize)}</span>
        </div>
      </div>
      <IconButton
        color="primary"
        variant="outlined"
        target="_blank"
        href={message.mediaUrl}
      >
        <GetApp />
      </IconButton>
    </div>
  );
};

MessageMediaDocument.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageMediaDocument;
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
import { throttle } from "throttle-typescript";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import MicIcon from "@mui/icons-material/Mic";

import { makeStyles } from "@mui/styles";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";


const plugins = [];

const canvas = document.createElement("canvas");
const ctx = canvas.getContext("2d");

// Define the waveform gradient
const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35);
gradient.addColorStop(0.3, "#E0E0E0"); // Light gray top color
gradient.addColorStop((canvas.height * 0.7) / canvas.height, "#E0E0E0"); // Light gray top color
gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, "#E0E0E0"); // Light gray line
gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, "#E0E0E0"); // Light gray line
gradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, "#E0E0E0"); // Light gray bottom color
gradient.addColorStop(1, "#E0E0E0"); // Light gray bottom color

// Define the progress gradient
const progressGradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35);
progressGradient.addColorStop(0.3, "#757575"); // Dark gray top color
progressGradient.addColorStop((canvas.height * 0.7) / canvas.height, "#757575"); // Dark gray top color
progressGradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, "#757575"); // Dark gray line
progressGradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, "#757575"); // Dark gray line
progressGradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, "#757575"); // Dark gray bottom color
progressGradient.addColorStop(1, "#757575"); // Dark gray bottom color

const LS_NAME = "audioMessageRate";

const useStyles = makeStyles(() => ({
  messageMediaAudio: {
    "& audio": {
      display: "none"
    },
  },
}));

export default function MessageMediaAudio(props) {
  // !
  // 1️⃣ Props
  const {
    src,
    id = "waveform",
    paperize = true,
    waveHeight = 48,
    showTimestamps = true,
    playPauseIconButtonProps,
    containerHeight = "auto",
    containerWidth = 320,
    size,
    fromMe,
    profilePicUrl,
    onError,
    onLoadedDuration,
  } = props;
  // 2️⃣ Data
  // State
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [audioRate, setAudioRate] = useState(parseFloat(localStorage.getItem(LS_NAME) || "1"));
  const [showButtonRate, setShowButtonRate] = useState(false);
  // Styles
  const classes = useStyles();
  // Ref
  const waveSurferRef = useRef(null);
  // 3️⃣ Functions
  const handleRateChange = () => {
    let newRate;
    switch (audioRate) {
      case 0.5: // if 0.5x, change to 1x
        newRate = 1;
        break;
      case 1: // if 1x, change to 1.5x
        newRate = 1.5;
        break;
      case 1.5: // if 1.5x, change to 2x
        newRate = 2;
        break;
      case 2: // if 2x, change to 0.5x
        newRate = 0.5;
        break;
      default:
        newRate = 1;
    }

    setAudioRate(newRate);
    localStorage.setItem(LS_NAME, newRate);

    if (waveSurferRef.current) {
      waveSurferRef.current.setPlaybackRate(newRate);
    }
  };

  const getInitializeWaveSurfer = (params) => {
    const {
      src,
    } = params;
  
    return function initializeWaveSurfer(waveSurfer) {
      if (!waveSurfer) {
        return;
      }
  
      waveSurferRef.current = waveSurfer;
  
      if (src) {
        waveSurfer.load(src);
      }
  
      const makePlaying = () => setPlaying(true);
      const makeNotPlaying = () => setPlaying(false);
  
      waveSurfer.on("loading", (n) => {
        setProgress(n);
      });
      waveSurfer.on("ready", () => {
        const audioDuration = waveSurfer.getDuration();

        setLoading(false);
        setEndTime(audioDuration);

        waveSurfer.setPlaybackRate(audioRate);

        if (onLoadedDuration) {
          onLoadedDuration(audioDuration);
        }
      });
      waveSurfer.on("play", makePlaying);
      waveSurfer.on(
        "audioprocess",
        throttle((n) => {
          setCurrentTime(n);
        }, 100)
      );
      waveSurfer.on("seek", () => {
        setCurrentTime(waveSurfer.getCurrentTime());
      });
      waveSurfer.on("finish", () => {
        makeNotPlaying();
        waveSurfer.seekTo(0);
      });
      ["finish", "destroy", "pause"].forEach((e) =>
        waveSurfer.on(e, makeNotPlaying)
      );
      waveSurfer.on("error", (e) => {
        makeNotPlaying();
        console.error(e);
        if (onError) {
          onError(); // Notifica o componente pai sobre o erro
        }
      });
    };
  };

  useEffect(
    () => () => {
      waveSurferRef.current?.destroy();
    },
    []
  );

  const mergedContainerStyle = {
    height: containerHeight,
    width: containerWidth,
    flexWrap: "wrap",
    backgroundColor: "transparent",
  };

  useEffect(() => {
    let timeoutId;
  
    if (waveSurferRef.current) {
      waveSurferRef.current.on("play", () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        setShowButtonRate(true);
      });
  
      waveSurferRef.current.on("finish", () => {
        if (showButtonRate === false) {
          clearTimeout(timeoutId);

          return;
        }

        timeoutId = setTimeout(() => setShowButtonRate(false), 1000);
      });
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [waveSurferRef, showButtonRate]);

  const avatarStyle = {
    position: "relative",
    display: "inline-block",
  };

  const iconStyle = {
    position: "absolute",
    fontSize: 24,
    color: "#111b21",
    borderRadius: "50%",
    padding: 2,
    bottom: fromMe ? -5 : -5,
    right: fromMe ? -5 : "auto",
    left: fromMe ? "auto" : -5,
  };

  return (
    <Stack
      sx={mergedContainerStyle}
      direction="row"
      component={paperize ? Paper : "div"}
      alignItems="center"
      spacing={0}
    >
      <Stack
        component={Box}
        direction="row"
        flexGrow={loading ? 0 : 1}
        height="100%"
        width="100%"
        alignItems="center"
        spacing={0}
      >
        <Box flexGrow={1} height="100%" width="100%" alignItems="center">
          <Grid container spacing={0} alignItems="center">
            {fromMe && (
              <Grid container xs={2} justifyContent="center">
                {showButtonRate ? (
                  <ButtonRate
                    audioRate={audioRate}
                    handleRateChange={handleRateChange}
                  />
                ) : (
                  <div style={avatarStyle}>
                    <Avatar alt="User Avatar" src={profilePicUrl} />
                    <MicIcon style={iconStyle} />
                  </div>
                )}
              </Grid>
            )}

            <Grid item xs={3}>
              <PlayPauseButton
                disabled={loading}
                playing={playing}
                waveSurferRef={waveSurferRef}
                playPauseIconButtonProps={{
                  size: size,
                  ...playPauseIconButtonProps,
                }}
              />
            </Grid>
            <Grid item xs={7} className={classes.messageMediaAudio}>
              <WaveSurfer
                onMount={getInitializeWaveSurfer({ src })}
                backend="MediaElement"
                plugins={plugins}
              >
                <WaveForm
                  id={id}
                  fillParent
                  mediaControls
                  waveColor={gradient}
                  progressColor={progressGradient}
                  barWidth={2}
                  height={waveHeight}
                  hideScrollbar={false}
                />
              </WaveSurfer>
              <Box flexGrow={1} height="100%" width="100%" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  {playing ? (
                    <TimeStamp time={currentTime} loading={loading} show={showTimestamps} />
                  ) : (
                    <TimeStamp time={endTime} loading={loading} show={showTimestamps} />
                  )}
                </Stack>
              </Box>
            </Grid>

            {!fromMe && (
              <Grid container xs={2} justifyContent="center">
                {showButtonRate ? (
                  <ButtonRate
                    audioRate={audioRate}
                    handleRateChange={handleRateChange}
                  />
                ) : (
                  <div style={avatarStyle}>
                    <Avatar alt="User Avatar" src={profilePicUrl} />
                    <MicIcon style={iconStyle} />
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>

      {loading ? (
        <LinearProgress variant="determinate" value={progress} />
      ) : null}
    </Stack>
  );
}
MessageMediaAudio.propTypes = {
  src: PropTypes.string.isRequired,
  id: PropTypes.string,
  display: PropTypes.oneOf(["waveform", "timeline"]),
  inline: PropTypes.bool,
  paperize: PropTypes.bool,
  waveColor: PropTypes.string,
  waveHeight: PropTypes.number,
  showTimestamps: PropTypes.bool,
  playPauseIconButtonProps: PropTypes.object,
  containerHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fromMe: PropTypes.bool,
  profilePicUrl: PropTypes.string,
  onError: PropTypes.func,
  onLoadedDuration: PropTypes.func,
};

function toTimeString(time) {
  const date = new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(time);

  const timeString = date.toTimeString().
    slice(3, 8);
  return timeString.startsWith("0") ? timeString.slice(1) : timeString; // Remova o zero inicial
}

function ButtonRate(props) {
  const { audioRate, handleRateChange } = props;

  return (
    <Chip
      onClick={handleRateChange}
      label={`${audioRate}x`}
    />
  );
}
ButtonRate.propTypes = {
  audioRate: PropTypes.number.isRequired,
  handleRateChange: PropTypes.func.isRequired,
};

function TimeStamp(props) {
  const { time, loading = false, show = true } = props;

  const defaultTimeStr = "0:00";
  const invalidTimeStr = "--:--";

  if (!show) {
    return null;
  }

  const timeStr = Number.isNaN(time) ? invalidTimeStr : toTimeString(time);

  return (
    <Box sx={containerStyle.timestamp}>
      <Typography
        variant="caption"
        color="#999"
      >
        {loading ? defaultTimeStr : timeStr}
      </Typography>
    </Box>
  );
}
TimeStamp.propTypes = {
  time: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  show: PropTypes.bool,
};

function PlayPauseButton(props) {
  const {
    disabled = false,
    playing,
    waveSurferRef,
    playPauseIconButtonProps = {},
  } = props;

  const handlePlay = () => {
    playOrPauseForWaveForm(waveSurferRef);
  };

  function playOrPauseForWaveForm(waveSurferRef) {
    const currentWaveSurfer = waveSurferRef.current;

    if (!currentWaveSurfer) return null;

    if (playing) {
      currentWaveSurfer.pause();
      return null;
    }

    currentWaveSurfer.play();
  }

  const { sx: iconButtonSx, ...restIconButtonProps } = playPauseIconButtonProps;
  const mergedSx = { ...containerStyle.playButton, ...iconButtonSx };

  return (
    <IconButton
      disabled={disabled}
      color="default"
      onClick={handlePlay}
      sx={{ ...mergedSx }}
      {...restIconButtonProps}
    >
      {playing ? <PauseIcon fontSize="inherit" /> : <PlayArrowIcon fontSize="inherit" />}
    </IconButton>
  );
}
PlayPauseButton.propTypes = {
  disabled: PropTypes.bool,
  playing: PropTypes.bool,
  waveSurferRef: PropTypes.object,
  playPauseIconButtonProps: PropTypes.object,
};

const containerStyle = {
  timestamp: {
    minWidth: "50px",
  },
  playButton: {
    fontSize: 40,
    margin: 0,
  },
};

import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
// context
import { AuthContext } from "../context/Auth/AuthContext";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";

const VcardPreview = ({ contact, numbers }) => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // History
  const history = useHistory();

  // @
  const [selectedContact, setContact] = useState({
    name: "",
    number: 0,
    profilePicUrl: ""
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          let contactObj = {
            name: contact,
            // number: numbers.replace(/\D/g, ""),
            number: numbers !== undefined && numbers.replace(/\D/g, ""),
            email: ""
          };
          const { data } = await api.post("/contact", contactObj);
          setContact(data);

        } catch (err) {
          console.log(err);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [contact, numbers]);

  const handleNewChat = async () => {
    try {

      const { data: ticket } = await api.post("/tickets", {
        contactId: selectedContact.id,
        userId: user.id,
        status: "open",
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minWidth: "250px" }}
    >
      <Grid item xs={2}>
        <Avatar src={selectedContact.profilePicUrl} />
      </Grid>
      <Grid item xs={9}>
        <Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="primary" gutterBottom>
          {selectedContact.name}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
        {user.profile === "agente" && (
          <Button
            fullWidth
            color="primary"
            onClick={handleNewChat}
            disabled={!selectedContact.number}
          >Conversar</Button>
        )}
      </Grid>
    </Grid>
  );
};
VcardPreview.propTypes = {
  contact: PropTypes.string.isRequired,
  numbers: PropTypes.string.isRequired
};

export default VcardPreview;
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
// components
import ButtonWithSpinner from "./ButtonWithSpinner";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const SendContact = ({ modalOpen, onClose, ticketId }) => {
  // !
  // State
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);


  // @
  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam },
          });

          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedContact(null);
  };

  const handleSendContact = async contactId => {
    if (!contactId) return;

    setLoading(true);
    try {
      await api.post(`/messages/${ticketId}`, {
        contact: contactId,
        params: { channel: "whatsapp", }
      });

    } catch (err) {
      toastError(err);
    }
    setLoading(false);

    handleClose();
  };

  const handleSelectOption = (e, newValue) => {
    setSelectedContact(newValue);
  };

  const renderOption = option => {
    return `${option.name} - ${option.number}`;
  };

  const renderOptionLabel = option => {
    return `${option.name} - ${option.number}`;
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          Contato
        </DialogTitle>

        <DialogContent dividers style={{ marginTop: 20, width: 350 }}>
          <FormControl variant="outlined" style={{ marginTop: 20, width: 300 }}>
            <Autocomplete
              options={options}
              loading={loading}
              style={{ width: 300 }}
              clearOnBlur
              autoHighlight
              freeSolo
              clearOnEscape
              getOptionLabel={renderOptionLabel}
              renderOption={renderOption}
              onChange={(e, newValue) => handleSelectOption(e, newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={i18n.t("newTicketModal.fieldLabel")}
                  variant="outlined"
                  autoFocus
                  onChange={e => setSearchParam(e.target.value)}
                  onKeyPress={e => {
                    if (loading || !selectedContact) return;
                    else if (e.key === "Enter") {
                      handleSendContact(selectedContact.id);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={!selectedContact}
            onClick={() => handleSendContact(selectedContact.id)}
            color="primary"
            loading={loading}
          >
            Enviar
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
};
SendContact.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ticketId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};


export default SendContact;

import React, { useState, useEffect, useReducer, useRef } from "react";
import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
  Fab,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  ArrowDownward,
} from "@material-ui/icons";
import PropTypes from "prop-types";
// assets
import whatsBackground from "../assets/wa-background.png";
// components
import MarkdownWrapper from "./MarkdownWrapper";
import VcardPreview from "./VcardPreview";
import LocationPreview from "./LocationPreview";
import MessageOptionsMenu from "./MessageOptionsMenu";
import MessageQuoted from "./MessageQuoted";

import MessageMediaAudio from "./MessageMediaAudio";
import MessageMediaDocument from "./MessageMediaDocument";
import MessageMediaImage from "./MessageMediaImage";
import MessageMediaVideo from "./MessageMediaVideo";
// errors
import toastError from "../errors/toastError";
// services
import openSocket from "../services/socket-io";
import api from "../services/api";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 3,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover [id^='messageActionsButton-']": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 3,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover [id^='messageActionsButton-']": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    opacity: "90%",
    padding: 0,
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "6px 70px 6px 8px",
  },
  filenameContentItem: {
    overflowWrap: "break-word",
    padding: "6px 0 6px 8px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  timestamp: {
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },
  timestampAudioLeft: {
    right: 53,
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  highlight: {
    backgroundColor: "yellow !important",
  },
  flash: {
    animation: "$flashAnimation 1s ease",
  },
  "@keyframes flashAnimation": {
    "0%": {
      backgroundColor: "#ffeb3b",
    },
    "100%": {
      backgroundColor: "transparent",
    },
  },
  scrollToBottomButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE_MEDIA_METADATA") {
    const { messageId, mediaMetadata } = action.payload;
    const newState = [...state];
    for (let i = 0; i < newState.length; i++) {
      if (newState[i].id === messageId) {
        newState[i] = { ...newState[i], mediaMetadata };
      }
      if (newState[i].quotedMsg?.id === messageId) {
        newState[i] = {
          ...newState[i],
          quotedMsg: {
            ...newState[i].quotedMsg,
            mediaMetadata
          }
        };
      }
    }
    return newState;
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticketId, isGroup, highlightedMessageId }) => {
  // !
  // Reducer
  const [messagesList, dispatch] = useReducer(reducer, []);
  // Ref
  const lastMessageRef = useRef();
  const currentTicketId = useRef(ticketId);
  // State
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [highlightedMessage, setHighlightedMessage] = useState(null);
  const [audioErrorIds, setAudioErrorIds] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  // Styles
  const classes = useStyles();
  // ...
  const messageOptionsMenuOpen = Boolean(anchorEl);

  // @
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber }
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  useEffect(() => {
    if (highlightedMessageId) {
      setHighlightedMessage(highlightedMessageId);
      setTimeout(() => {
        setHighlightedMessage(null);
      }, 1000);
    }
  }, [highlightedMessageId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const scrollToQuotedMessage = (messageId) => {
    const element = document.getElementById(`message-${messageId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.classList.add(classes.flash);
      setTimeout(() => {
        element.classList.remove(classes.flash);
      }, 1000);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const scrollBottom = (scrollHeight - scrollTop) - clientHeight;

    setShowScrollButton(scrollBottom > 100);

    if (!hasMore) return;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleAudioError = (messageId) => {
    setAudioErrorIds((prev) => [...prev, messageId]);
  };

  const handleAudioMetadata = (messageId, duration) => {
    dispatch({
      type: "UPDATE_MESSAGE_MEDIA_METADATA",
      payload: { messageId, mediaMetadata: { audioDuration: duration } }
    });
  };

  const renderMessageByMediaType = (message) => {
    if (audioErrorIds.includes(message.id)) {
      return <MessageMediaDocument message={message} />;
    }

    if (message.mediaType === "location" && message.body.split("|").length >= 2) {
      const locationParts = message.body.split("|");
      const linkLocation = locationParts[1];

      let descriptionLocation = null;
      if (locationParts.length > 2) descriptionLocation = locationParts[2];

      return <LocationPreview link={linkLocation} description={descriptionLocation} />;
    }
    else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";

      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");

        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }

          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }

      return <VcardPreview contact={contact} numbers={obj[0]?.number} />;
    }
    /*else if (message.mediaType === "multi_vcard") {  	
      if (message.body !== null && message.body !== "") {
        let newBody = JSON.parse(message.body)
        return (
          <>
            {
            newBody.map(v => (
              <VcardPreview contact={v.name} numbers={v.number} />
            ))
            }
          </>
        )
      } else return (<></>)
    }*/
    else if (message.mediaType === "image") {
      return <MessageMediaImage imageUrl={message.mediaUrl} mediaExtension={message.mediaExtension} />;
    }
    else if (message.mediaType === "audio") {
      return (
        <MessageMediaAudio 
          id={`audio-${message.id}`}
          inline 
          src={message.mediaUrl} 
          fromMe={message.fromMe} 
          profilePicUrl={message.contact?.profilePicUrl}
          onError={() => handleAudioError(message.id)}
          onLoadedDuration={(dur) => handleAudioMetadata(message.id, dur)}
        />
      );
    }
    else if (message.mediaType === "video") {
      return <MessageMediaVideo src={message.mediaUrl} />;
    }
    else if (message.mediaType === "chat") {
      return (
        <div className={classes.textContentItem}>
          <MarkdownWrapper>{message.body}</MarkdownWrapper>
        </div>
      );
    }
    else { // Document
      return (
        <MessageMediaDocument message={message} />
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        const isHighlighted = message.id === highlightedMessage;

        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div id={`message-${message.id}`} className={clsx(classes.messageLeft, { [classes.highlight]: isHighlighted })}>
                <IconButton
                  variant="contained"
                  size="small"
                  id={`messageActionsButton-${message.id}`}
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}

                {message.quotedMsg && (
                  <MessageQuoted 
                    message={message}
                    onQuotedClick={() => scrollToQuotedMessage(message.quotedMsg?.id)}
                  />
                )}

                {renderMessageByMediaType(message)}  

                <Typography 
                  variant="caption" 
                  className={clsx(classes.timestamp, {
                    [classes.timestampAudioLeft]: message.mediaType === "audio",
                  })}
                >
                  {format(parseISO(message.createdAt), "HH:mm")}
                </Typography>
              </div>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={message.id}>
            {renderDailyTimestamps(message, index)}
            {renderMessageDivider(message, index)}

            <div id={`message-${message.id}`} className={clsx(classes.messageRight, { [classes.highlight]: isHighlighted })}>
              <IconButton
                variant="contained"
                size="small"
                id={`messageActionsButton-${message.id}`}
                disabled={message.isDeleted}
                className={classes.messageActionsButton}
                onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
              >
                <ExpandMore />
              </IconButton>

              {message.quotedMsg && (
                <MessageQuoted 
                  message={message}
                  onQuotedClick={() => scrollToQuotedMessage(message.quotedMsg?.id)}
                />
              )}
              {renderMessageByMediaType(message)}

              <div
                className={clsx({
                  [classes.textContentItemDeleted]: message.isDeleted,
                })}
              >
                {message.isDeleted && (
                  <Block
                    color="disabled"
                    fontSize="small"
                    className={classes.deletedIcon}
                  />
                )}

                <Typography 
                  variant="caption" 
                  className={classes.timestamp}
                >
                  {format(parseISO(message.createdAt), "HH:mm")}
                  {renderMessageAck(message)}
                </Typography>
              </div>
            </div>
          </React.Fragment>
        );
      });

      return viewMessagesList;
    }

    return <div>Say hello to your new contact!</div>;
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />

      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
      {showScrollButton && (
        <Fab
          color="primary"
          className={classes.scrollToBottomButton}
          onClick={handleScrollToBottom}
        >
          <ArrowDownward />
        </Fab>
      )}
    </div>
  );
};
MessagesList.propTypes = {
  ticketId: PropTypes.string.isRequired,
  isGroup: PropTypes.bool.isRequired,
  highlightedMessageId: PropTypes.string,
};

export default MessagesList;
import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(() => ({
  form: {
    marginLeft: 8,
  },
  selectInput: {
    padding: "11px 12px 10px",
    backgroundColor: "#f0f2f5",
  },
}));

const TicketsQueueSelect = ({
  userQueues,
  selectedQueueIds = [],
  onChange,
}) => {
  // !
  // Styles
  const classes = useStyles();

  // @
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <FormControl classes={{ root: classes.form }}>
      <Select
        multiple
        displayEmpty
        variant="filled"
        value={selectedQueueIds}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
        classes={{ root: classes.selectInput }}
      >
        {userQueues?.length > 0 && userQueues.map(queue => (
          <MenuItem dense key={queue.id} value={queue.id}>
            <Checkbox
              style={{
                color: queue.color,
              }}
              size="small"
              color="primary"
              checked={selectedQueueIds.indexOf(queue.id) > -1}
            />
            <ListItemText primary={queue.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
TicketsQueueSelect.propTypes = {
  userQueues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
  selectedQueueIds: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
};

export default TicketsQueueSelect;

import React, { useRef } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  nativeVideo: {
    width: "330px",
    height: "250px",
    paddingBottom: 10
  },
}));

const Video = ({ url }) => {
  // !
  // Ref
  const videoRef = useRef(null);
  // Styles
  const classes = useStyles();

  // :
  return (
    <>
      <video ref={videoRef} controls className={classes.nativeVideo}>
        <source src={url} type="video/mp4"></source>
      </video>
    </>
  );
};
Video.propTypes = {
  url: PropTypes.string.isRequired,
  isFullUrl: PropTypes.bool,
};

export default Video;
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MicIcon from "@mui/icons-material/Mic";

const useStyles = makeStyles(() => ({
  messageQuotedContainerLeft: {
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    margin: 3,
    cursor: "pointer",
  },
  messageQuotedContainerRight: {
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    margin: 3,
    cursor: "pointer",    
  },
  messageQuotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  messageQuotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },
  messageQuoted: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },
  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  }
}));

const formatAudioDuration = (duration) => {
  if (!duration) return "Áudio";

  const totalSeconds = Math.floor(duration);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

const MessageQuoted = ({ message, onQuotedClick }) => {
  // Styles
  const classes = useStyles();
  // State
  const [mediaMetadata, setMediaMetadata] = useState(null);

  useEffect(() => {
    if (message.quotedMsg?.mediaType === "audio" && message.quotedMsg?.mediaMetadata) {
      setMediaMetadata(message.quotedMsg.mediaMetadata);
    }
  }, [message]); 

  return (
    <div
      className={clsx(classes.messageQuotedContainerLeft, {
        [classes.messageQuotedContainerRight]: message.fromMe,
      })}
      onClick={onQuotedClick}
    >
      <span
        className={clsx(classes.messageQuotedSideColorLeft, {
          [classes.messageQuotedSideColorRight]: message.quotedMsg?.fromMe,
        })}
      ></span>
      <div className={classes.messageQuoted}>
        {!message.quotedMsg?.fromMe && (
          <span className={classes.messageContactName}>
            {message.quotedMsg?.contact?.name}
          </span>
        )}
        {message.quotedMsg?.mediaType === "audio" ? (
          <>
            <MicIcon style={{ fontSize: 16, marginRight: 4 }} />
            <span>{ formatAudioDuration(mediaMetadata?.audioDuration) }</span>
          </>
        ) : (
          message.quotedMsg?.body
        )}
      </div>
    </div>
  );
};
MessageQuoted.propTypes = {
  message: PropTypes.object.isRequired,
  onQuotedClick: PropTypes.func.isRequired,
};
export default MessageQuoted;
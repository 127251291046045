import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  messageMediaVideo: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginBottom: 17,
  },
}));

const MessageMediaVideo = ({ src }) => {
  const classes = useStyles();

  return <video className={classes.messageMediaVideo} src={src} controls />;
};
MessageMediaVideo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default MessageMediaVideo;

import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

// eslint-disable-next-line no-unused-vars
const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
InputComponent.propTypes = {
  inputRef: PropTypes.func
};

const OutlinedDiv = ({
  InputProps,
  children,
  InputLabelProps,
  label,
  ...other
}) => {
  return (
    <TextField
      {...other}
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
      InputProps={{
        inputComponent: InputComponent,
        ...InputProps
      }}
      inputProps={{ children: children }}
    />
  );
};
OutlinedDiv.propTypes = {
  InputProps: PropTypes.object,
  children: PropTypes.node,
  InputLabelProps: PropTypes.object,
  label: PropTypes.string
};

export default OutlinedDiv;
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";

export function TagsFilter({ onFiltered }) {
  // !
  // State
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  // @
  useEffect(() => {
    async function fetchData() {
      await loadTags();
    }

    fetchData();
  }, []);

  const loadTags = async () => {
    try {
      const { data } = await api.get("/tags/list");
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  const filterOptions = (options) => {
    return options.filter(option => !selecteds.some(selected => selected.id === option.id));
  };

  return (
    <Box style={{ padding: 10 }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selecteds}
        onChange={(e, v) => onChange(v)}
        getOptionLabel={(option) => option.name}
        filterOptions={filterOptions}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              style={{
                backgroundColor: option.color || "#eee",
                textShadow: "1px 1px 1px #000",
                color: "white",
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Filtro por Tags"
          />
        )}
      />
    </Box>
  );
}
TagsFilter.propTypes = {
  onFiltered: PropTypes.func.isRequired,
};
import React, { useContext } from "react";

import { AuthContext } from "../context/Auth/AuthContext";

import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core";
import PropTypes from "prop-types";
// context
import { ReplyMessageContext } from "../context/ReplyingMessage/ReplyingMessageContext";
// translate
import { i18n } from "../translate/i18n";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  // !
  // Context
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  // State
  const { user } = useContext(AuthContext);

  // @
  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  return (
    <>
      {user.profile === "agente" && (
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          <MenuItem onClick={hanldeReplyMessage}>
            {i18n.t("messageOptionsMenu.reply")}
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
MessageOptionsMenu.propTypes = {
  message: PropTypes.object.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
};

export default MessageOptionsMenu;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";
import PropTypes from "prop-types";
// services
import api from "../services/api_file";

const useStyles = makeStyles(() => ({
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingBottom: 17
  },
}));

const ModalImageCors = ({ imageUrl, mediaExtension }) => {
  // !
  // States
  const [fetching, setFetching] = useState(true);
  const [blobUrl, setBlobUrl] = useState("");
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    if (!imageUrl) return;

    const fetchImage = async () => {
      const { data } = await api.get(imageUrl, {
        responseType: "blob",
        withCredentials: false
      });

      const mimeType = `image/${mediaExtension}`;

      const url = window.URL.createObjectURL(
        new Blob([data], { type: mimeType })
      );

      setBlobUrl(url);
      setFetching(false);
    };

    fetchImage();
  }, [imageUrl]);

  return (
    <ModalImage
      className={classes.messageMedia}
      smallSrcSet={fetching ? imageUrl : blobUrl}
      medium={fetching ? imageUrl : blobUrl}
      large={fetching ? imageUrl : blobUrl}
      alt="image"
    />
  );
};
ModalImageCors.propTypes = {
  imageUrl: PropTypes.string,
  mediaExtension: PropTypes.string,
};

export default ModalImageCors;

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Board from "react-trello";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
// context
import { AuthContext } from "../../context/Auth/AuthContext";
// services
import api from "../../services/api";
// translate
import { i18n } from "../../translate/i18n";
// utils
import { formatCreatedAt } from "../../utils/dateUtils";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  
}));

const Kanban = () => {
  // Context
  const { user, checkLogin } = useContext(AuthContext);
  // Styles
  const classes = useStyles();
  // History
  const history = useHistory();
  // State
  const [tags, setTags] = useState([]);
  const [tickets, setTickets] = useState([]);

  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTickets = async (jsonString) => {
    try {
      
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString)
        }
      });

      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };
  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];

      setTags(fetchedTags);

      // Fetch tickets after fetching tags
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  // @
  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  useEffect(() => {
    fetchTags();
    const intervalId = setInterval(fetchTags, 5000); // Recarrega a cada 5 segundos

    return () => clearInterval(intervalId);
  }, []);

  const [file, setFile] = useState({
    lanes: []
  });

  const popularCards = () => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("Sem Tag"),
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          description: (
            <div>
              {/* Label exibido abaixo do título */}
              <div style={{
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                marginBottom: "8px" // Espaço entre o label e a descrição
              }}>
                Ticket nº {formatCreatedAt(ticket.createdAt) + ticket.id.toString()}
              </div>
              
              <p>
                Última mensagem
                <br />
                {ticket.lastMessage}
              </p>
              <button 
                className={classes.button} 
                onClick={() => {
                  handleCardClick(ticket.id);
                }}>
                  Ver Ticket
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.id,
        })),
      },
      ...tags.map(tag => {
        const filteredTickets = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          cards: filteredTickets.map(ticket => ({
            id: ticket.id.toString(),
            title: (
              <div>
                <strong>{ticket.contact.name}</strong>
              </div>
            ),
            description: (
              <div>
                {/* Label exibido abaixo do título */}
                <div style={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  marginBottom: "8px" // Espaço entre o label e a descrição
                }}>
                  Ticket nº {formatCreatedAt(ticket.createdAt) + ticket.id.toString()}
                </div>
                
                <p>
                  Última mensagem
                  <br />
                  {ticket.lastMessage}
                </p>
                <button 
                  className={classes.button} 
                  onClick={() => {
                    handleCardClick(ticket.id);
                  }}>
                    Ver Ticket
                </button>
              </div>
            ),
            draggable: true,
            href: "/tickets/" + ticket.id,
          })),
          style: { backgroundColor: tag.color, color: "white" }
        };
      }),
    ];

    setFile({ lanes });
  };

  const handleCardClick = (id) => {  
    // console.log("Clicked on card with ID:", id);
    history.push("/tickets/" + id + "?kanban=1");
  };

  useEffect(() => {
    popularCards();
  }, [tags, tickets]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
        
      await api.delete(`/ticket-tags/${targetLaneId}`);
      toast.success("Ticket Tag Removido!");
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
      toast.success("Ticket Tag Adicionado com Sucesso!");

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Board 
        data={file} 
        onCardMoveAcrossLanes={handleCardMove}
        style={{backgroundColor: "rgba(252, 252, 252, 0.03)", width: "80vw" }}
        laneStyle={{ width: "300px" }} // Aumenta a largura de cada coluna
        cardStyle={{ width: "280px", whiteSpace: "normal", wordWrap: "break-word"  }}
      />
    </div>
  );
};


export default Kanban;

import React from "react";
import PropTypes from "prop-types";
import MarkdownWrapper from "./MarkdownWrapper";

import { makeStyles } from "@material-ui/core/styles";

// Image
import { InsertPhoto } from "@material-ui/icons";
// Video
import { Videocam } from "@material-ui/icons";
// Audio
import { Mic } from "@material-ui/icons";
// Document
import { Description } from "@material-ui/icons";
// Contact
import { Person } from "@material-ui/icons";
// Location
import { LocationOn } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  iconAligned: {
    marginBottom: "-2px",
  },
}));

const TicketListItemLastMessage = ({ lastMessageId, lastMessageText }) => {
  const classes = useStyles();

  if (!lastMessageText) return null;

  // !
  const extension = lastMessageText.split(".").pop();

  // ? Image
  const supportedImagesExtensions = [
    "jpg",
    "jpeg",
    "png",
    "webp",
  ];
  if (
    supportedImagesExtensions.includes(extension) || lastMessageText.startsWith(`image-${lastMessageId}|`)
  ) {
    return (
      <span>
        <InsertPhoto fontSize="inherit" className={classes.iconAligned} /> Foto
      </span>
    );
  }
  // ? Video
  const supportedVideosExtensions = [
    "mp4",
  ];
  if (
    supportedVideosExtensions.includes(extension) || lastMessageText.startsWith(`video-${lastMessageId}|`)
  ) {
    return (
      <span>
        <Videocam fontSize="inherit" className={classes.iconAligned} /> Vídeo
      </span>
    );
  }
  // ? Audio
  const supportedAudioExtensions = [
    "aac",
    // "amr",
    "flac",
    "m4a",
    // "m4r",
    "ogg",
    "mp3",
    "oga",
    "wav",
  ];
  if (
    supportedAudioExtensions.includes(extension) || lastMessageText.startsWith(`audio-${lastMessageId}`)
  ) {
    if (lastMessageText.startsWith(`audio-${lastMessageId}|`)) {
      // audio|audioTimeDuration
      const audioTimeDuration = lastMessageText.split("|")[1];
      // convert float (in seconds) to time (mm:ss)
      // e.g. 1.5 => 0:01, 4.98 => 0:04
      const minutes = Math.floor(audioTimeDuration / 60);
      const seconds = Math.floor(audioTimeDuration % 60).toString().padStart(2, "0");

      return (
        <span>
          <Mic fontSize="inherit" className={classes.iconAligned} /> {audioTimeDuration > 0 ? `${minutes}:${seconds}` : "Áudio"}
        </span>
      );
    }

    return (
      <span>
        <Mic fontSize="inherit" className={classes.iconAligned} /> Áudio
      </span>
    );
  }
  // ? Document
  const supportedDocumentExtensions = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "txt",
    "zip",
    "rar",
    "html",
    "epub",
  ];
  if (
    supportedDocumentExtensions.includes(extension) || lastMessageText.startsWith(`document-${lastMessageId}|`)
  ) {
    return (
      <span>
        <Description fontSize="inherit" className={classes.iconAligned} /> Documento
      </span>
    );
  }
  // ? Contact
  if (
    lastMessageText.startsWith(`vcard-${lastMessageId}|`)
  ) {
    // vcard|contactName
    const contactName = lastMessageText.split("|")[1];

    return (
      <span>
        <Person fontSize="inherit" className={classes.iconAligned} /> {contactName}
      </span>
    );
  }
  // ? Location
  if (
    lastMessageText.startsWith(`location-${lastMessageId}|`)
  ) {
    // location|...
    return (
      <span>
        <LocationOn fontSize="inherit" className={classes.iconAligned} /> Localização
      </span>
    );
  }

  // Text
  return (<MarkdownWrapper>{lastMessageText}</MarkdownWrapper>);
};
TicketListItemLastMessage.propTypes = {
  lastMessageId: PropTypes.string,
  lastMessageText: PropTypes.string,
};

export default TicketListItemLastMessage;
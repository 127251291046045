import React from "react";
import PropTypes from "prop-types";
import ModalImageCors from "./ModalImageCors";

const MessageMediaImage = ({ imageUrl, mediaExtension }) => {
  return <ModalImageCors imageUrl={imageUrl} mediaExtension={mediaExtension} />;
};
MessageMediaImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  mediaExtension: PropTypes.string.isRequired,
};

export default MessageMediaImage;

import React, { useState, useEffect } from "react";
import { isArray, isEmpty } from "lodash";
import moment from "moment";

import { toast } from "react-toastify";

import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import CallIcon from "@material-ui/icons/Call";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import TimerIcon from "@material-ui/icons/Timer";
import {
  Group, GroupAdd, Contacts,
  AddAlarm,
  MobileOff, DeviceUnknown,
  Today, EventAvailable, ConfirmationNumber
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

import { grey, blue } from "@material-ui/core/colors";
// components
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import TableTicketAgentRating from "../../components/Dashboard/TableTicketAgentRating";
// context
// import { AuthContext } from "../../context/Auth/AuthContext";
// hooks
import useDashboard from "../../hooks/useDashboard";
// import useContacts from "../../hooks/useContacts";
// .
// import { ChatsUser } from "./ChartsUser";
// import { ChartsDate } from "./ChartsDate";

const useStyles = makeStyles((theme) => ({
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  iframeDashboard: {
    width: "100%",
    height: "calc(100vh - 64px)",
    border: "none",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 120,
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  card0: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#9400D3",
    color: "#eee",
  },
  card00: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#8B1C62",
    color: "#eee",
  },
  card1: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#11bf42",
    color: "#eee",
  },
  card2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#748e9d",
    color: "#eee",
  },
  card3: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#e53935",
    color: "#eee",
  },
  card4: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#cc991b",
    color: "#eee",
  },
  card5: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#47a7f6",
    color: "#eee",
  },
  card6: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#b87d77",
    color: "#eee",
  },
  card7: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#7bc780",
    color: "#eee",
  },
  card8: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#b05c38",
    color: "#eee",
  },
  card9: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#bd3c58",
    color: "#eee",
  },
  fixedHeightPaper2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Dashboard = () => {
  // !
  // Context
  // const { user } = useContext(AuthContext);
  // Styles
  const classes = useStyles();
  // State
  const [counters, setCounters] = useState({});
  const [tableTicketAgentRating, setTableTicketAgentRating] = useState([]);

  const [period, setPeriod] = useState(0);
  const [filterType, setFilterType] = useState(1);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);

  // const [showFilter, setShowFilter] = useState(false);
  // const [queueTicket, setQueueTicket] = useState(false);
  // .
  const { find } = useDashboard();

  // let newDate = new Date();
  // let date = newDate.getDate();
  // let month = newDate.getMonth() + 1;
  // let year = newDate.getFullYear();
  // let now = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`}`;

  // var userQueueIds = [];
  // if (user.queues && user.queues.length > 0) {
  //   userQueueIds = user.queues.map((q) => q.id);
  // }

  // @
  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
  }, []);
  
  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (filterType === 1 && moment(dateTo).diff(moment(dateFrom), "days") > 30) {
      toast.error("O intervalo de datas não pode exceder 30 dias");
      setLoading(false);
      return;
    }

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    // # counters
    setCounters(data.counters);

    // # tableTicketAgentRating
    if (isArray(data.tableTicketAgentRating)) {
      setTableTicketAgentRating(data.tableTicketAgentRating);
    } else {
      setTableTicketAgentRating([]);
    }

    setLoading(false);
  }

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }
  
  // const GetContacts = (all) => {
  //   let props = {};
  //   if (all) {
  //     props = {};
  //   }
  //   const { count } = useContacts(props);
  //   return count;
  // };

  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Data Inicial"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Data Final"
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      );
    }

    return (
      <Grid item xs={12} sm={6} md={4}>
        <FormControl className={classes.selectContainer}>
          <InputLabel id="period-selector-label">Período</InputLabel>
          <Select
            labelId="period-selector-label"
            id="period-selector"
            value={period}
            onChange={(e) => handleChangePeriod(e.target.value)}
          >
            <MenuItem value={0}>Nenhum selecionado</MenuItem>
            <MenuItem value={3}>Últimos 3 dias</MenuItem>
            <MenuItem value={7}>Últimos 7 dias</MenuItem>
            <MenuItem value={15}>Últimos 15 dias</MenuItem>
            <MenuItem value={30}>Últimos 30 dias</MenuItem>
          </Select>
          <FormHelperText>Selecione o período desejado</FormHelperText>
        </FormControl>
      </Grid>
    );
  }

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} justifyContent="flex-end">
          {/* FILTROS */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
              <Select
                labelId="period-selector-label"
                value={filterType}
                onChange={(e) => handleChangeFilterType(e.target.value)}
              >
                <MenuItem value={1}>Filtro por Data</MenuItem>
                <MenuItem value={2}>Filtro por Período</MenuItem>
              </Select>
              <FormHelperText>Selecione o período desejado</FormHelperText>
            </FormControl>
          </Grid>
          {renderFilters()}
          {/* FILTROS - BOTAO FILTRAR */}
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="default"
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>

          {/* TICKETS - EM ATENDIMENTO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card1}
              style={{ overflow: "hidden" }}
              elevation={4}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Tickets em atendimento
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.supportHappening}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <CallIcon
                    style={{
                      fontSize: 100,
                      color: "#0b708c",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* TICKETS - AGUARDANDO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card2}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Tickets aguardando
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.supportPending}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <HourglassEmptyIcon
                    style={{
                      fontSize: 100,
                      color: "#47606e",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* TICKETS - FINALIZADOS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card3}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Tickets finalizados
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.supportFinished}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <CheckCircleIcon
                    style={{
                      fontSize: 100,
                      color: "#5852ab",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* CONTATOS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Total de Contatos
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.totalContacts}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Group
                    style={{
                      fontSize: 100,
                      color: "#8c6b19",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* CONTATOS CRIADOS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card5}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Contatos Criados
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.createdContacts}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <GroupAdd
                    style={{
                      fontSize: 100,
                      color: "#3f51b5",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* CONTATOS ATUALIZADOS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card6}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Contatos Atualizados
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.updatedContacts}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Contacts
                    style={{
                      fontSize: 100,
                      color: "#ff9800",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* T.M. DE ATENDIMENTO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card8}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    T.M. de Conversa
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.avgSupportTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <AccessAlarmIcon
                    style={{
                      fontSize: 100,
                      color: "#7a3f26",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* T.M. DE ESPERA */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card9}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    T.M. de Espera
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.avgWaitTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TimerIcon
                    style={{
                      fontSize: 100,
                      color: "#8a2c40",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* T.T. DE ATENDIMENTO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card7}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    T.T. de Atendimento
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.totalSupportTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <AddAlarm
                    style={{
                      fontSize: 100,
                      color: "#4caf50",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* CONEXÕES */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card1}
              style={{ overflow: "hidden" }}
              elevation={4}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                Conexões Ativas
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.totalWhatsappSessions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <MobileFriendlyIcon
                    style={{
                      fontSize: 100,
                      color: "#fff",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* CONEXÕES INATIVAS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card0}
              style={{ overflow: "hidden" }}
              elevation={4}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Conexões Inativas
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.inactiveWhatsappSessions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <DeviceUnknown
                    style={{
                      fontSize: 100,
                      color: "#fff",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* CONEXÕES COM ERRO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card3}
              style={{ overflow: "hidden" }}
              elevation={4}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Conexões com Erro
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.errorWhatsappSessions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <MobileOff
                    style={{
                      fontSize: 100,
                      color: "#fff",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* CAMPANHAS CRIADAS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Campanhas Criadas
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.createdCampaigns}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Today
                    style={{
                      fontSize: 100,
                      color: "#3f51b5",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* CAMPANHAS FINALIZADAS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card5}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Campanhas Finalizadas
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.finishedCampaigns}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <EventAvailable
                    style={{
                      fontSize: 100,
                      color: "#5852ab",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* CAMPANHAS AGENDADAS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card6}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                    Campanhas Agendadas
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {counters.scheduledCampaigns}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <ConfirmationNumber
                    style={{
                      fontSize: 100,
                      color: "#ff9800",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Typography variant="h3" component="h3" gutterBottom style={{ marginTop: 20 }}>
          Agentes
        </Typography>
        <Grid container spacing={3}>
          <TableTicketAgentRating loading={loading} data={tableTicketAgentRating} />
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;

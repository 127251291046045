import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { isArray, isString } from "lodash";

import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";

export function TagsContainer({ ticket }) {
  // !
  // State
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  // Ref
  const isMounted = useRef(true);

  // @
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      loadTags().then(() => {
        if (Array.isArray(ticket.tags)) {
          setSelecteds(ticket.tags);
        } else {
          setSelecteds([]);
        }
      });
    }
  }, [ticket]);

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post("/tags", data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const loadTags = async () => {
    try {
      const { data } = await api.get("/tags/list");
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post("/tags/sync", data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value, reason) => {
    let optionsChanged = [];
    if (reason === "create-option") {
      if (isArray(value)) {
        for (let item of value) {
          if (isString(item)) {
            const newTag = await createTag({ name: item });
            optionsChanged.push(newTag);
          } else {
            optionsChanged.push(item);
          }
        }
      }
      await loadTags();
    } else {
      optionsChanged = value;
    }
    setSelecteds(optionsChanged);
    await syncTags({ ticketId: ticket.id, tags: optionsChanged });
  };

  const filterOptions = (options) => {
    return options.filter(option => !selecteds.some(selected => selected.id === option.id));
  };

  return (
    <Paper style={{ padding: 12 }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selecteds}
        freeSolo
        onChange={(e, v, r) => onChange(v, r)}
        getOptionLabel={(option) => option.name}
        filterOptions={filterOptions}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              style={{
                background: option.color || "#eee",
                color: "#FFF",
                marginRight: 1,
                fontWeight: 600,
                borderRadius: 3,
                fontSize: "0.8em",
                whiteSpace: "nowrap"
              }}
              label={option.name.toUpperCase()}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Tags" />
        )}
        PaperComponent={({ children }) => (
          <Paper style={{ width: 400, marginLeft: 12 }}>
            {children}
          </Paper>
        )}
      />
    </Paper>
  );
}
TagsContainer.propTypes = {
  ticket: PropTypes.object.isRequired
};